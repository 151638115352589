import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import {
    ACCOUNT_PATH,
    CUSTOMER_PATH,
    CUSTOMERS_PATH,
    DASHBOARD_PATH,
    DOCUMENTS_PATH,
    LOGIN_PATH,
    ORDER_PATH,
    ORDERS_ADD_PATH,
    ORDERS_PATH,
    PRODUCT_PATH,
    PRODUCTS_ATTRIBUTES_PATH,
    PRODUCTS_PATH,
    TASK_PATH
} from "../../config/navigation";
import CircularProgressBackdrop from "./CircularProgressBackdrop";

const Dashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/dashboard" */ '../dashboard/Dashboard' ) );
const Account = lazy( () => import( /* webpackChunkName: "scripts/routes/account" */ '../account/Account' ) );
const OrdersDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/orders" */ '../order/OrderDashboard' ) );
const Order = lazy( () => import( /* webpackChunkName: "scripts/routes/orders" */ '../order/Order' ) );
const AddOrder = lazy( () => import( /* webpackChunkName: "scripts/routes/orders" */ '../order/AddOrder' ) );
const TaskDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/tasks" */ '../task/TaskDashboard' ) );
const Product = lazy( () => import( /* webpackChunkName: "scripts/routes/products" */ '../product/Product' ) );
const ProductDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/products" */ '../product/ProductDashboard' ) );
const ProductAttributesDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/products/configure" */ '../product/ProductAttributesDashboard' ) );
const Customer = lazy( () => import( /* webpackChunkName: "scripts/routes/customers" */ '../customer/Customer' ) );
const CustomerDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/customers" */ '../customer/CustomerDashboard' ) );
const DocumentsDashboard = lazy( () => import( /* webpackChunkName: "scripts/routes/documents" */ '../documents/DocumentsDashboard' ) );
const NotFound = lazy( () => import( /* webpackChunkName: "scripts/routes/dashboard" */ '../common/NotFound' ) );

const Init = () =>
{
    return <Redirect to={ DASHBOARD_PATH }/>;
}

export function Router()
{
    const isLoggedIn = true;

    return (
        // <BrowserRouter>
        <Suspense fallback={ <CircularProgressBackdrop isOpen={ true }/> }>
            <Switch>
                <Route exact path={ '/' } component={ Init }/>

                <PrivateRoute isAuthenticated={ isLoggedIn } redirectTo={ LOGIN_PATH } exact path={ DASHBOARD_PATH }
                              component={ Dashboard }/>

                <PrivateRoute
                    isAuthenticated={ isLoggedIn }
                    redirectTo={ LOGIN_PATH }
                    path={ ACCOUNT_PATH }
                    component={ Account }
                />

                {/*Orders*/ }
                <PrivateRoute isAuthenticated={ isLoggedIn }
                              redirectTo={ LOGIN_PATH }
                              path={ ORDER_PATH }
                              component={ Order }
                />

                <PrivateRoute isAuthenticated={ isLoggedIn } redirectTo={ LOGIN_PATH } exact path={ ORDERS_ADD_PATH }
                              component={ AddOrder }/>

                <PrivateRoute isAuthenticated={ isLoggedIn } redirectTo={ LOGIN_PATH } exact path={ ORDERS_PATH }
                              component={ OrdersDashboard }/>

                {/*Products*/ }
                <PrivateRoute isAuthenticated={ isLoggedIn }
                              redirectTo={ LOGIN_PATH }
                              path={ PRODUCT_PATH }
                              component={ Product }
                />
                <PrivateRoute isAuthenticated={ isLoggedIn }
                              redirectTo={ LOGIN_PATH }
                              path={ PRODUCTS_ATTRIBUTES_PATH }
                              component={ ProductAttributesDashboard }
                />
                <PrivateRoute isAuthenticated={ isLoggedIn }
                              redirectTo={ LOGIN_PATH }
                              path={ PRODUCTS_PATH }
                              component={ ProductDashboard }
                />
                <PrivateRoute isAuthenticated={ isLoggedIn } redirectTo={ LOGIN_PATH } exact path={ TASK_PATH }
                              component={ TaskDashboard }/>

                {/*Customers*/ }
                <PrivateRoute
                    isAuthenticated={ isLoggedIn }
                    redirectTo={ LOGIN_PATH }
                    path={ CUSTOMER_PATH }
                    component={ Customer }
                />
                <PrivateRoute isAuthenticated={ isLoggedIn }
                              redirectTo={ LOGIN_PATH }
                              path={ CUSTOMERS_PATH }
                              component={ CustomerDashboard }
                />
                <PrivateRoute
                    isAuthenticated={ isLoggedIn }
                    path={ DOCUMENTS_PATH }
                    redirectTo={ LOGIN_PATH }
                    component={ DocumentsDashboard }
                />

                <Route path="*" component={ NotFound }/>
            </Switch>
        </Suspense>
        // </BrowserRouter>
    );
}