import {
    countryCodesMap,
    gendersMap,
    orderStatusMap,
    productDeliveryStatusMap,
    productProcessStatusMap
} from "../app/constants";
import moment from "moment";

export const accountLoginForm = [
    {
        name: 'email',
        type: 'email',
        label: 'E-Mail',
        required: true,
    },
    {
        name: 'password',
        type: 'password',
        label: 'Password',
        required: true,
    },
];

export const accountForgetPasswordFormConfig = [
    {
        name: 'email',
        type: 'email',
        label: 'E-Mail',
        required: true,
    },
];

export const accountFormConfig = [
    {
        name: 'gender',
        type: 'select',
        options: gendersMap,
        label: 'Anrede',
        required: true,
    },
    {
        name: 'lastname',
        type: 'text',
        label: 'Nachname',
        required: true,
    },
    {
        name: 'firstname',
        type: 'text',
        label: 'Vorname',
        required: true,
    },
    {
        name: 'email',
        type: 'text',
        label: 'E-Mail',
        required: true,
    },
];

export const accountPasswordFormConfig = [
    {
        name: 'password',
        type: 'password',
        label: 'Password',
        required: true,
    },
    {
        name: 'password_repeat',
        type: 'password',
        label: 'Password (Wiederholung)',
        required: true,
    },
    {
        name: 'password_confirm',
        type: 'password',
        label: 'Ihr aktuelles Passwort',
        required: true,
    },
];

const dateTimeResponseToLocalFormatter = ( { value } ) =>
{
    const result = moment( value );

    if ( !result.isValid() )
    {
        return value;
    }

    return result.format( 'YYYY-MM-DDTHH:mm:ss' );
}

export const getProductFormConfig = ( { manufacturers = [] } = {} ) =>
{
    return [
        {
            name: 'number',
            type: 'text',
            label: 'Monats/Quartals Nr.',
            required: false,
        },
        {
            name: 'keyNumber',
            type: 'text',
            label: 'Schlüssel Nr.',
            required: false,
        },
        {
            name: 'articleNumber',
            type: 'text',
            label: 'Bestell Nr.',
            required: false,
        },
        {
            name: 'externalArticleNumber',
            type: 'text',
            label: 'Auftrag Nr.',
            required: false,
        },
        {
            name: 'name',
            type: 'text',
            label: 'Name',
            required: true,
        },
        {
            name: 'engine',
            type: 'text',
            label: 'Motor',
            required: false,
        },
        {
            name: 'chassis',
            type: 'text',
            label: 'Chassis',
            required: false,
        },
        {
            name: 'color',
            type: 'text',
            label: 'Farbe',
            required: false,
        },
        {
            name: 'description',
            type: 'text',
            label: 'Extras',
            required: false,
        },
        {
            name: 'availableAt',
            type: 'datetime-local',
            label: 'Plantermin',
            required: true,
            format: dateTimeResponseToLocalFormatter
        },
        {
            name: 'manufacturer',
            type: 'select',
            label: 'Hersteller',
            options: manufacturers,
            required: true,
        },
        {
            name: 'chassisNumber',
            type: 'text',
            label: 'Fahrgestell Nr.',
            required: false,
        },
        {
            name: 'bodyNumber',
            type: 'text',
            label: 'Aufbau Nr.',
            required: false,
        },
        {
            name: 'deliveryStatus',
            type: 'select',
            label: 'Lieferstatus',
            options: productDeliveryStatusMap,
            required: true,
        },
        {
            name: 'processStatus',
            type: 'select',
            label: 'Prozessstatus',
            options: productProcessStatusMap,
            required: true,
        },
    ];
}

export const getProductFilterFormConfig = ( { manufacturers = [] } = {} ) =>
{
    return [
        {
            name: 'keyNumber',
            type: 'text',
            label: 'Schlüssel Nr.',
            required: false,
        },
        {
            name: 'articleNumber',
            type: 'text',
            label: 'Bestell Nr.',
            required: false,
        },
        {
            name: 'externalArticleNumber',
            type: 'text',
            label: 'Auftrag Nr.',
            required: false,
        },
        {
            name: 'name',
            type: 'text',
            label: 'Name',
            required: true,
        },
        {
            name: 'engine',
            type: 'text',
            label: 'Motor',
            required: false,
        },
        {
            name: 'chassis',
            type: 'text',
            label: 'Chassis',
            required: false,
        },
        {
            name: 'color',
            type: 'text',
            label: 'Farbe',
            required: false,
        },
        {
            name: 'description',
            type: 'text',
            label: 'Extras',
            required: false,
        },
        {
            name: 'availableAtAfter',
            type: 'datetime-local',
            label: 'Plantermin (von)',
            format: dateTimeResponseToLocalFormatter
        },
        {
            name: 'availableAtBefore',
            type: 'datetime-local',
            label: 'Plantermin (bis)',
            format: dateTimeResponseToLocalFormatter
        },
        {
            name: 'manufacturer',
            type: 'select',
            label: 'Hersteller',
            options: manufacturers,
            required: true,
        },
        {
            name: 'chassisNumber',
            type: 'text',
            label: 'Fahrgestell Nr.',
            required: false,
        },
        {
            name: 'bodyNumber',
            type: 'text',
            label: 'Aufbau Nr.',
            required: false,
        },
        {
            name: 'deliveryStatus',
            type: 'select',
            label: 'Lieferstatus',
            options: [
                {
                    value: null,
                    label: '-',
                },
                ...productDeliveryStatusMap,
            ]
        },
        {
            name: 'processStatus',
            type: 'select',
            label: 'Prozessstatus',
            options: [
                {
                    value: null,
                    label: '-',
                },
                ...productProcessStatusMap,
            ],
        },
    ];
}

export const productNoteFormConfig = [
    {
        name: 'text',
        type: 'textarea',
        label: 'Notiz',
    },
];

export const orderFormConfig = [
    {
        name: 'status',
        type: 'select',
        label: 'Status',
        options: orderStatusMap,
        value: 'new',
    },
];

export const customerFormConfig = [
    {
        name: 'company',
        type: 'text',
        label: 'Firma',
    },
    {
        name: 'gender',
        type: 'select',
        options: gendersMap,
        label: 'Anrede',
        required: true,
    },
    {
        name: 'lastname',
        type: 'text',
        label: 'Nachname',
        required: true,
    },
    {
        name: 'firstname',
        type: 'text',
        label: 'Vorname',
        required: true,
    },
    {
        name: 'email',
        type: 'text',
        label: 'E-Mail',
        required: true,
    },
    {
        name: 'phone',
        type: 'text',
        label: 'Telefonnummer',
    },
];

export const customerAddressFormConfig = [
    {
        name: 'company',
        type: 'text',
        label: 'Firma',
    },
    {
        name: 'gender',
        type: 'select',
        options: gendersMap,
        label: 'Anrede',
        required: true,
    },
    {
        name: 'lastname',
        type: 'text',
        label: 'Nachname',
        required: true,
    },
    {
        name: 'firstname',
        type: 'text',
        label: 'Vorname',
        required: true,
    },
    {
        name: 'street',
        type: 'text',
        label: 'Straße',
        required: true,
    },
    {
        name: 'cityCode',
        type: 'text',
        label: 'Postleitzahl',
        required: true,
    },
    {
        name: 'city',
        type: 'text',
        label: 'Stadt',
        required: true,
    },
    {
        name: 'countryCode',
        type: 'select',
        options: countryCodesMap,
        label: 'Land',
        required: true,
    },
];