import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ProductIcon from "@mui/icons-material/AirportShuttleTwoTone";
import CustomersIcon from "@mui/icons-material/People";
import DocumentsIcon from "@mui/icons-material/PhotoLibrary";

// public routes
export const LOGIN_PATH = '/login'

// public routes
export const DASHBOARD_PATH = '/dashboard';
export const ACCOUNT_PATH = '/users/:userId';
export const ACCOUNT_PATCH_PATH = '/users/:userId/patch';
export const ACCOUNT_PASSWORD_PATCH_PATH = '/users/:userId/password/patch';
export const PRODUCTS_PATH = '/products';
export const PRODUCTS_INSERT_PATH = '/products/insert';
export const PRODUCTS_FILTER_PATH = '/products/filter';
export const PRODUCT_PATH = '/product/:productId';
export const PRODUCT_PATCH_PATH = '/product/:productId/patch';
export const PRODUCT_COPY_PATH = '/product/:productId/copy';
export const PRODUCT_DELETE_PATH = '/product/:productId/delete';
export const PRODUCT_ASSIGNEES_PATH = '/product/:productId/assignees';
export const PRODUCT_ATTRIBUTES_VALUES_PATH = '/product/:productId/attributes/values';
export const PRODUCT_PRICES_VALUES_PATH = '/product/:productId/prices/values';
export const PRODUCT_DOCUMENTS_PATH = '/product/:productId/documents';
export const PRODUCTS_ATTRIBUTES_PATH = '/products/attributes';
export const PRODUCTS_ATTRIBUTES_INSERT_PATH = '/products/attributes/insert';
export const PRODUCTS_ATTRIBUTES_PATCH_PATH = '/products/attributes/:attributeId/patch';
export const PRODUCTS_ATTRIBUTES_DELETE_PATH = '/products/attributes/:attributeId/delete';
export const TASK_PATH = '/tasks';
export const ORDERS_PATH = '/orders';
export const ORDERS_ADD_PATH = '/orders/add';
export const ORDER_PATH = '/order/:orderId';
export const ORDER_PATCH_PATH = '/order/:orderId/patch';
export const ORDER_DOCUMENTS_PATH = '/order/:orderId/documents';
export const CUSTOMERS_PATH = '/customers';
export const CUSTOMERS_INSERT_PATH = '/customers/insert';
export const CUSTOMER_PATH = '/customer/:customerId';
export const CUSTOMER_PROFILE_PATH = '/customer/:customerId/profile';
export const CUSTOMER_ORDERS_PATH = '/customer/:customerId/orders';
export const CUSTOMERS_PATCH_PATH = '/customer/:customerId/patch';
export const CUSTOMERS_ADDRESS_INSERT_PATH = '/customer/:customerId/address/insert';
export const CUSTOMERS_ADDRESS_PATCH_PATH = '/customer/:customerId/address/:addressId/patch';
export const CUSTOMERS_ADDRESS_DELETE_PATH = '/customer/:customerId/address/:addressId/delete';
export const CUSTOMERS_DELETE_PATH = '/customer/:customerId/delete';
export const DOCUMENTS_PATH = '/documents';
export const DOCUMENTS_DELETE_PATH = '/documents/:documentId/delete';

export const PRIMARY_NAVIGATION = [
    {
        to: DASHBOARD_PATH,
        text: 'Dashboard',
        icon: <DashboardIcon/>,
    },
    {
        to: PRODUCTS_PATH,
        text: 'Fahrzeuge',
        icon: <ProductIcon/>,
    },
    {
        to: DOCUMENTS_PATH,
        text: 'Dokumente',
        icon: <DocumentsIcon/>,
    },
    {
        to: CUSTOMERS_PATH,
        text: 'Kunden',
        icon: <CustomersIcon/>,
    },
]

export const SECONDARY_NAVIGATION = [
    {
        to: PRODUCTS_ATTRIBUTES_PATH,
        text: 'Einstellungen',
        icon: <SettingsIcon/>,
    }
];