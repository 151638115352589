import { API_PRODUCT, getHost } from "../../app/constants";
import { PATCH } from "../../utils/fetch";
import getOptions from "../options";

export default function patchProduct( productId, body, options )
{
    return PATCH(
        getHost() + API_PRODUCT( productId ),
        {
            ...getOptions( options ),
            body
        }
    );
}