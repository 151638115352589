import { API_CUSTOMERS, getHost } from "../../app/constants";
import { POST } from "../../utils/fetch";
import getOptions from "../options";

export default function insertCustomer( body, options )
{
    return POST(
        getHost() + API_CUSTOMERS,
        {
            ...getOptions( options ),
            body
        }
    );
}