import { DELETE, GET, PATCH, POST } from "../utils/fetch";
import {
    API_CUSTOMER,
    API_CUSTOMER_ADDRESS,
    API_CUSTOMER_ADDRESSES,
    API_CUSTOMER_ORDER,
    API_CUSTOMER_ORDERS,
    API_CUSTOMERS,
    getHost
} from "../app/constants";
import getOptions from "./options";

export default class Customer
{
    constructor( token )
    {
        this.options = {
            authorization: {
                token,
            }
        };
    }

    insert( body )
    {
        return POST(
            getHost() + API_CUSTOMERS,
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    patch( customerId, body )
    {
        return PATCH(
            getHost() + API_CUSTOMER( customerId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    delete( customerId )
    {
        return DELETE(
            getHost() + API_CUSTOMER( customerId ),
            {
                ...getOptions( this.options ),
            }
        );
    }

    findAll()
    {
        return GET(
            getHost() + API_CUSTOMERS,
            getOptions( this.options )
        );
    }

    findById( customerId )
    {
        return GET(
            getHost() + API_CUSTOMER( customerId ),
            getOptions( this.options )
        );
    }

    getAddresses( customerId )
    {
        return GET(
            getHost() + API_CUSTOMER_ADDRESSES( customerId ),
            getOptions( this.options )
        );
    }

    insertAddress( customerId, body )
    {
        return POST(
            getHost() + API_CUSTOMER_ADDRESSES( customerId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    patchAddress( customerId, addressId, body )
    {
        return PATCH(
            getHost() + API_CUSTOMER_ADDRESS( customerId, addressId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    deleteAddress( customerId, addressId )
    {
        return DELETE(
            getHost() + API_CUSTOMER_ADDRESS( customerId, addressId ),
            {
                ...getOptions( this.options ),
            }
        );
    }

    getAddress( customerId, addressId )
    {
        return GET(
            getHost() + API_CUSTOMER_ADDRESS( customerId, addressId ),
            getOptions( this.options )
        );
    }

    getOrders( customerId )
    {
        return GET(
            getHost() + API_CUSTOMER_ORDERS( customerId ),
            getOptions( this.options )
        );
    }

    getOrder( customerId, orderId )
    {
        return GET(
            getHost() + API_CUSTOMER_ORDER( customerId, orderId ),
            getOptions( this.options )
        );
    }
}

export const useCustomerApi = ( token ) => new Customer( token );