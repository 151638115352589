import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import { useOrdersApi } from "../../api/orders";

export const ORDER_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const ORDER_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const ORDER_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const ORDER_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const fetchOrders = createAsyncThunk(
    'orders/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const OrderApi = useOrdersApi( findAuthenticationToken( getState() ) );
        return await OrderApi.findAll().then( ( response ) =>
            {
                const { data = {}, errors = null } = response;

                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchOrderById = createAsyncThunk(
    'orders/findById',
    async ( orderId, { getState, rejectWithValue } ) =>
    {
        const OrderApi = useOrdersApi( findAuthenticationToken( getState() ) );
        return await OrderApi.findById( orderId ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

const initialState = {
    findById: {
        data: {},
        errors: null,
        status: ORDER_STATUS_IDLE,
        updatedAt: null,
    },
    findAll: {
        data: [],
        errors: null,
        status: ORDER_STATUS_IDLE,
        updatedAt: null,
    },
};

export const orderSlice = createSlice( {
        name: 'orders',
        initialState,
        // slice-specific reducers here
        reducers: {},
        // normal reducer logic
        extraReducers: {
            [ fetchOrders.pending ]: ( state ) =>
            {
                state.findAll.status = ORDER_STATUS_PENDING;
            },
            [ fetchOrders.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.updatedAt = new Date().getTime() / 1000;
                state.findAll.status = ORDER_STATUS_FULFILLED;
            },
            [ fetchOrders.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.findAll.data;
                state.findAll.error = action.error;
                state.findAll.status = ORDER_STATUS_REJECTED;
            },
            [ fetchOrderById.pending ]: ( state ) =>
            {
                state.findById.status = ORDER_STATUS_PENDING;
            },
            [ fetchOrderById.fulfilled ]: ( state, action ) =>
            {
                state.findById.data = action.payload;
                state.findById.updatedAt = new Date().getTime() / 1000;
                state.findById.status = ORDER_STATUS_FULFILLED;
            },
            [ fetchOrderById.rejected ]: ( state, action ) =>
            {
                state.findById.data = initialState.findById.data;
                state.findById.error = action.error;
                state.findById.status = ORDER_STATUS_REJECTED;
            },
        }
    }
);

// export const {} = orderSlice.actions;

export const findOrders = state => state.orders.findAll;
export const findOrderById = state => state.orders.findById;

export default orderSlice.reducer;