import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthenticationToken } from "../../api/account/getAuthenticationToken";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import { useAccountApi } from "../../api/account";

export const fetchAuthentication = createAsyncThunk(
    'account/fetchAuthentication',
    async ( body, { rejectWithValue } ) =>
    {
        return await getAuthenticationToken( body ).then( ( response ) =>
        {
            const { data = {}, errors = null } = response;

            if ( errors !== null )
            {
                return rejectWithValue( errors );
            }

            return data;
        } );
    }
);

export const fetchAccount = createAsyncThunk(
    'account/fetchAccount',
    async ( payload, { getState, rejectWithValue } ) =>
    {
        const AccountApi = useAccountApi( findAuthenticationToken( getState() ) );
        return await AccountApi.find().then( ( response ) =>
        {
            const { data = {}, errors = null } = response;

            if ( errors !== null )
            {
                return rejectWithValue( errors );
            }

            return data;
        } );
    }
);

export const fetchAccounts = createAsyncThunk(
    'account/fetchAll',
    async ( payload, { getState, rejectWithValue } ) =>
    {
        const AccountApi = useAccountApi( findAuthenticationToken( getState() ) );
        return await AccountApi.findAll().then( ( response ) =>
        {
            const { data = {}, errors = null } = response;

            if ( errors !== null )
            {
                return rejectWithValue( errors );
            }

            return data;
        } );
    }
);

export const ACCOUNT_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const ACCOUNT_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const ACCOUNT_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const ACCOUNT_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

const initialState = {
    findAuthentication: {
        data: null,
        errors: null,
        status: ACCOUNT_STATUS_IDLE,
    },
    findAccount: {
        data: null,
        settings: {},
        errors: null,
        status: ACCOUNT_STATUS_IDLE,
    },
    findAll: {
        data: null,
        settings: {},
        errors: null,
        status: ACCOUNT_STATUS_IDLE,
    },
}

export const accountSlice = createSlice(
    {
        name: 'account',
        initialState,
        reducers: {
            persistAuthentication: ( state, action ) =>
            {
                state.findAuthentication.data = action.payload;
                state.findAuthentication.status = ACCOUNT_STATUS_FULFILLED;
            }
        },
        extraReducers: {
            [ fetchAuthentication.pending ]: ( state ) =>
            {
                state.findAuthentication.status = ACCOUNT_STATUS_PENDING;
            },
            [ fetchAuthentication.fulfilled ]: ( state, action ) =>
            {
                state.findAuthentication.data = action.payload;
                state.findAuthentication.status = ACCOUNT_STATUS_FULFILLED;
            },
            [ fetchAuthentication.rejected ]: ( state, action ) =>
            {
                state.findAuthentication.data = initialState.data;
                state.findAuthentication.errors = action.payload;
                state.findAuthentication.status = ACCOUNT_STATUS_REJECTED;
            },
            [ fetchAccount.pending ]: ( state ) =>
            {
                state.findAccount.status = ACCOUNT_STATUS_PENDING;
            },
            [ fetchAccount.fulfilled ]: ( state, action ) =>
            {
                state.findAccount.data = action.payload;
                state.findAccount.status = ACCOUNT_STATUS_FULFILLED;
            },
            [ fetchAccount.rejected ]: ( state, action ) =>
            {
                state.findAccount.data = initialState.data;
                state.findAccount.errors = action.error;
                state.findAccount.status = ACCOUNT_STATUS_REJECTED;
            },
            [ fetchAccounts.pending ]: ( state ) =>
            {
                state.findAll.status = ACCOUNT_STATUS_PENDING;
            },
            [ fetchAccounts.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.status = ACCOUNT_STATUS_FULFILLED;
            },
            [ fetchAccounts.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.data;
                state.findAll.errors = action.error;
                state.findAll.status = ACCOUNT_STATUS_REJECTED;
            },
        }
    }
);

export const { persistAuthentication } = accountSlice.actions;

export const findAuthentication = state => state.account.findAuthentication;
export const findAuthenticationToken = state => state.account.findAuthentication.data?.apiToken ?? null;
export const findAccount = state => state.account.findAccount;
export const findAccounts = state => state.account.findAll;

export default accountSlice.reducer;