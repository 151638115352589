class UnprocessableEntity extends Error
{
    constructor( message )
    {
        super( message );
        this.name = 'UnprocessableEntity';
        this.code = 411;
    }
}

export default UnprocessableEntity;