import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { persistAuthentication } from "../accountSlice";
import { useAccountApi } from "../../../api/account";
import { useSnackbar } from "notistack";
import CircularProgressBackdrop from "../../common/CircularProgressBackdrop";
import FormBuilder from "../../../modules/FormBuilder";
import { accountForgetPasswordFormConfig } from "../../../config/forms";

const PREFIX = 'PasswordForm';

const classes = {
    marginTop: `${ PREFIX }-marginTop`
};

const Form = styled( 'form' )( ( { theme } ) => ( {
    [ `& .${ classes.marginTop }` ]: {
        marginTop: theme.spacing( 1 ),
    }
} ) );

const PasswordForm = ( { onSuccess, onError } ) =>
{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [ formData, setFormData ] = useState( {} );
    const [ isFetching, setIsFetching ] = useState( false );
    const AccountApi = useAccountApi( null );

    const handleChange = ( { event } ) =>
    {
        const { name, value } = event.target;
        setFormData( {
            ...formData,
            [ name ]: value,
        } );
    };

    const handleSubmit = ( event ) =>
    {
        event.preventDefault();

        setIsFetching( true );
        AccountApi.resetPassword( formData )
                  .then( ( response ) =>
                  {
                      enqueueSnackbar( 'Das Passwort wurde erfolgreich zurückgesetzt..', { variant: "success" } );
                      setIsFetching( false );

                      if ( typeof onSuccess === 'function' )
                      {
                          onSuccess( { response } );
                      }

                      dispatch( persistAuthentication( response.data ) );
                  } )
                  .catch( error =>
                  {
                      enqueueSnackbar( 'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Daten.', { variant: "error" } );
                      setIsFetching( false );

                      if ( typeof onError === 'function' )
                      {
                          onError( { error } );
                      }
                  } )
    }

    const formConfig = accountForgetPasswordFormConfig.map( input =>
    {
        return {
            ...input,
            value: formData[ input.name ] ?? '',
            onChange: handleChange,
        }
    } )

    return (
        <Form onSubmit={ handleSubmit }>
            <CircularProgressBackdrop isOpen={ isFetching }/>
            <FormBuilder config={ formConfig }/>
            <Button
                type={ "submit" }
                size={ "large" } className={ clsx( classes.marginTop ) }
                variant={ "contained" } color={ "primary" } fullWidth>Zurücksetzen</Button>
        </Form>
    );
}
export default PasswordForm;