import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import { useManufacturersApi } from "../../api/manufacturers";

export const MANUFACTURER_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const MANUFACTURER_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const MANUFACTURER_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const MANUFACTURER_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const fetchManufacturers = createAsyncThunk(
    'manufacturers/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const ManufacturersApi = useManufacturersApi( findAuthenticationToken( getState() ) );
        return await ManufacturersApi.findAll().then( ( response ) =>
            {
                const { data = {}, errors = null } = response;

                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

const initialState = {
    findAll: {
        data: [],
        errors: null,
        status: MANUFACTURER_STATUS_IDLE,
        updatedAt: null,
    },
};

export const manufacturerSlice = createSlice( {
        name: 'manufacturers',
        initialState,
        // slice-specific reducers here
        reducers: {},
        // normal reducer logic
        extraReducers: {
            [ fetchManufacturers.pending ]: ( state ) =>
            {
                state.findAll.status = MANUFACTURER_STATUS_PENDING;
            },
            [ fetchManufacturers.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.updatedAt = new Date().getTime() / 1000;
                state.findAll.status = MANUFACTURER_STATUS_FULFILLED;
            },
            [ fetchManufacturers.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.findAll.data;
                state.findAll.error = action.error;
                state.findAll.status = MANUFACTURER_STATUS_REJECTED;
            },
        }
    }
);

// export const {} = manufacturerSlice.actions;

export const findManufacturers = state => state.manufacturers.findAll;

export default manufacturerSlice.reducer;