import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ( { component: Component, isAuthenticated, redirectTo, ...rest } ) => (
    <Route { ...rest } render={ ( props ) => (
        isAuthenticated === true
            ? <Component { ...props } />
            : <Redirect to={ redirectTo } state={ props }/>
    ) }/>
);

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType( [
        PropTypes.object,
        PropTypes.func,
    ] ).isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string.isRequired,
};

export default PrivateRoute;
