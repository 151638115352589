FormData.prototype.serialize = function ( config = {} )
{
    const urlSearchParams = new URLSearchParams( this );

    // remove form data
    if ( Array.isArray( config.delete ) )
    {
        config.delete.forEach( ( key ) => urlSearchParams.delete( key ) );
    }

    return urlSearchParams.toString();
};

FormData.prototype.asObject = function ()
{
    return Object.fromEntries( this );
};
