import { createSlice } from '@reduxjs/toolkit';


const initialState = {}

export const applicationSlice = createSlice( {
        name: 'application',
        initialState,
        reducers: {
            reset: ( state ) =>
            {
                // nothing to do
            }
        },
    }
);

export const { reset } = applicationSlice.actions;

export default applicationSlice.reducer;