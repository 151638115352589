const getOptions = ( { authorization } ) =>
{
    const headers = {};

    if ( authorization && authorization.token )
    {
        headers[ 'Authorization' ] = `Bearer ${ authorization.token }`;
    }

    return {
        headers,
    };
}

export default getOptions;