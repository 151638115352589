import getOptions from "../options";
import { API_CUSTOMER, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProductAttributeById( customerId, options )
{
    return GET(
        getHost() + API_CUSTOMER( customerId ),
        getOptions( options )
    );
}