import getOptions from "../options";
import { API_PRODUCTS_PRICES, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProductPrices( options )
{
    return GET(
        getHost() + API_PRODUCTS_PRICES,
        getOptions( options )
    );
}