import PropTypes from 'prop-types';
import React from "react";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export const RenderTextField = ( { type, name, label, value, format = null, onChange, ...rest } ) =>
{
    return (
        <TextField
            variant="outlined"
            type={ type }
            label={ label ?? name }
            name={ name }
            value={ typeof format === "function" ? format( { value } ) : value ?? '' }
            onChange={ ( event ) => onChange( { event, name, } ) }
            { ...rest }
        />
    )
};

export const RenderSelect = ( { type, name, label, value, onChange, options, ...rest } ) =>
{
    return (
        <FormControl variant="outlined">
            <InputLabel id={ `label-${ name }` }>{ label }</InputLabel>
            <Select
                labelId={ `label-${ name }` }
                id={ `select-${ name }` }
                label={ label ?? name }
                name={ name }
                value={ value ?? '' }
                onChange={ ( event ) => onChange( { event, name, } ) }
                { ...rest }
            >
                {
                    options.map( ( option, key ) =>
                    {
                        return <MenuItem key={ key } value={ option.value }>{ option.label }</MenuItem>
                    } )
                }
            </Select>
        </FormControl>
    )
}

const Input = ( data ) =>
{
    switch ( data.type )
    {
        case 'select':
            return <RenderSelect { ...data } />
        case 'text':
        case 'password':
        case 'email':
        case 'number':
        case 'hidden':
        case 'datetime-local':
            return <RenderTextField { ...data }/>
        case 'textarea':
            return <RenderTextField
                multiline={ true }
                rows={ 5 }
                { ...data }
            />
        default:
            return null;
    }
}

const FormBuilder = ( { config } ) =>
{
    if ( !config || config.length === 0 )
    {
        return null;
    }
    return config.map( ( option ) => <Input key={ option.name } { ...option }/> );
}

FormBuilder.propTypes = {
    config: PropTypes.arrayOf(
        PropTypes.shape( {
                name: PropTypes.string.isRequired,
                type: PropTypes.oneOf( [
                    'text',
                    'textarea',
                    'password',
                    'email',
                    'number',
                    'select',
                    'checkbox',
                    'radio',
                    'datetime-local',
                ] ).isRequired,
                label: PropTypes.string,
                value: PropTypes.any,
                selected: PropTypes.bool,
                checked: PropTypes.bool,
                options: PropTypes.arrayOf(
                    PropTypes.shape( {
                            value: PropTypes.oneOfType( [
                                PropTypes.string,
                                PropTypes.number,
                            ] ),
                            label: PropTypes.string.isRequired,
                        }
                    )
                ),
                required: PropTypes.bool,
                onChange: PropTypes.func,
            }
        )
    ).isRequired,
}

export default FormBuilder;