import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import api_uploadDocuments from "../../api/documents/uploadDocuments";
import api_getDocuments from "../../api/documents/getDocuments";

export const DOCUMENT_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const DOCUMENT_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const DOCUMENT_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const DOCUMENT_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const uploadDocuments = createAsyncThunk(
    'documents/upload',
    async ( { body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_uploadDocuments( body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

const initialState = {
    upload: {
        data: {},
        errors: null,
        status: DOCUMENT_STATUS_IDLE,
    },
    findAll: {
        data: [],
        errors: null,
        status: DOCUMENT_STATUS_IDLE,
        updatedAt: null,
    },
};

export const documentsSlice = createSlice( {
        name: 'documents',
        initialState,
        // slice-specific reducers here
        reducers: {
            // resetPersistProduct: (state) => {
            //     Object.assign(
            //         state,
            //         {
            //             insertProduct: initialState.insertProduct,
            //         }
            //     )
            // },
        },
        // normal reducer logic
        extraReducers: {
            [ uploadDocuments.pending ]: ( state ) =>
            {
                state.upload.status = DOCUMENT_STATUS_PENDING;
            },
            [ uploadDocuments.fulfilled ]: ( state, action ) =>
            {
                state.upload.data = action.payload;
                state.upload.status = DOCUMENT_STATUS_FULFILLED;
            },
            [ uploadDocuments.rejected ]: ( state, action ) =>
            {
                state.upload.data = initialState.upload.data;
                state.upload.error = action.error;
                state.upload.status = DOCUMENT_STATUS_REJECTED;
            },
        }
    }
);

// export const {
//     resetPersistProduct,
//     resetPatchProduct,
//     resetDeleteProduct,
//     resetPersistAttribute,
//     resetPatchAttribute,
//     resetDeleteAttribute
// } = documentsSlice.actions;

export const findUploadDocuments = state => state.documents.upload;

export default documentsSlice.reducer;