import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import api_getProducts from "../../api/product/getProducts";
import api_getProductById from "../../api/product/getProductById";
import api_insertProduct from "../../api/product/insertProduct";
import api_patchProduct from "../../api/product/patchProduct";
import api_deleteProduct from "../../api/product/deleteProduct";
import api_getProductAttributes from "../../api/product/getProductAttributes";
import api_insertProductAttribute from "../../api/product/insertProductAttribute";
import api_patchProductAttribute from "../../api/product/patchProductAttribute";
import api_deleteProductAttribute from "../../api/product/deleteProductAttributeById";
import api_getProductAttributeById from "../../api/product/getProductAttributeById";
import api_getProductPrices from "../../api/product/getProductPrices";

export const PRODUCT_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const PRODUCT_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const PRODUCT_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const PRODUCT_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const insertProduct = createAsyncThunk(
    'products/insert',
    async ( { body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_insertProduct( body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const patchProduct = createAsyncThunk(
    'products/patch',
    async ( { productId, body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_patchProduct( productId, body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const deleteProduct = createAsyncThunk(
    'products/delete',
    async ( productId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_deleteProduct( productId, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            )
            .catch( () =>
            {
                return rejectWithValue( {} );
            } );
    }
);

export const fetchProducts = createAsyncThunk(
    'products/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getProducts( { authorization } ).then( ( response ) =>
            {
                const { data = {}, errors = null } = response;

                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchProductById = createAsyncThunk(
    'products/findById',
    async ( productId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getProductById( productId, { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchProductAttributes = createAsyncThunk(
    'products/attributes/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getProductAttributes( { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchProductAttributeById = createAsyncThunk(
    'products/attributes/findById',
    async ( attributeId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getProductAttributeById( attributeId, { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const insertProductAttribute = createAsyncThunk(
    'products/attributes/insert',
    async ( { body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_insertProductAttribute( body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const patchProductAttribute = createAsyncThunk(
    'products/attributes/patch',
    async ( { attributeId, body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_patchProductAttribute( attributeId, body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const deleteProductAttribute = createAsyncThunk(
    'products/attributes/delete',
    async ( attributeId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_deleteProductAttribute( attributeId, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            )
            .catch( () =>
            {
                return rejectWithValue( {} );
            } );
    }
);

export const fetchProductPrices = createAsyncThunk(
    'products/prices/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getProductPrices( { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

const initialState = {
    insertProduct: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    patchProduct: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    deleteProduct: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    findById: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
        updatedAt: null,
    },
    findAll: {
        data: [],
        errors: null,
        status: PRODUCT_STATUS_IDLE,
        updatedAt: null,
    },
    findAllAttributes: {
        data: [],
        errors: null,
        status: PRODUCT_STATUS_IDLE,
        updatedAt: null,
    },
    findAttributeById: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
        updatedAt: null,
    },
    insertAttribute: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    patchAttribute: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    deleteAttribute: {
        data: {},
        errors: null,
        status: PRODUCT_STATUS_IDLE,
    },
    findAllPrices: {
        data: [],
        errors: null,
        status: PRODUCT_STATUS_IDLE,
        updatedAt: null,
    },
};

export const productSlice = createSlice( {
        name: 'product',
        initialState,
        // slice-specific reducers here
        reducers: {
            resetPersistProduct: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        insertProduct: initialState.insertProduct,
                    }
                )
            },
            resetPatchProduct: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        patchProduct: initialState.patchProduct,
                    }
                )
            },
            resetDeleteProduct: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        deleteProduct: initialState.deleteProduct,
                    }
                )
            },
            resetPersistAttribute: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        insertAttribute: initialState.insertAttribute,
                    }
                )
            },
            resetPatchAttribute: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        patchAttribute: initialState.patchAttribute,
                    }
                )
            },
            resetDeleteAttribute: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        deleteAttribute: initialState.deleteAttribute,
                    }
                )
            },
        },
        // normal reducer logic
        extraReducers: {
            [ insertProduct.pending ]: ( state ) =>
            {
                state.insertProduct.status = PRODUCT_STATUS_PENDING;
            },
            [ insertProduct.fulfilled ]: ( state, action ) =>
            {
                state.insertProduct.data = action.payload;
                state.insertProduct.status = PRODUCT_STATUS_FULFILLED;
            },
            [ insertProduct.rejected ]: ( state, action ) =>
            {
                state.insertProduct.data = initialState.insertProduct.data;
                state.insertProduct.error = action.error;
                state.insertProduct.status = PRODUCT_STATUS_REJECTED;
            },
            [ patchProduct.pending ]: ( state ) =>
            {
                state.patchProduct.status = PRODUCT_STATUS_PENDING;
            },
            [ patchProduct.fulfilled ]: ( state, action ) =>
            {
                state.patchProduct.data = action.payload;
                state.patchProduct.status = PRODUCT_STATUS_FULFILLED;
            },
            [ patchProduct.rejected ]: ( state, action ) =>
            {
                state.patchProduct.data = initialState.patchProduct.data;
                state.patchProduct.error = action.error;
                state.patchProduct.status = PRODUCT_STATUS_REJECTED;
            },
            [ deleteProductAttribute.pending ]: ( state ) =>
            {
                state.deleteProduct.status = PRODUCT_STATUS_PENDING;
            },
            [ deleteProductAttribute.fulfilled ]: ( state, action ) =>
            {
                state.deleteProduct.data = action.payload;
                state.deleteProduct.status = PRODUCT_STATUS_FULFILLED;
            },
            [ deleteProduct.rejected ]: ( state, action ) =>
            {
                state.deleteProduct.data = initialState.deleteProduct.data;
                state.deleteProduct.error = action.error;
                state.deleteProduct.status = PRODUCT_STATUS_REJECTED;
            },
            [ fetchProducts.pending ]: ( state ) =>
            {
                state.findAll.status = PRODUCT_STATUS_PENDING;
            },
            [ fetchProducts.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.updatedAt = new Date().getTime() / 1000;
                state.findAll.status = PRODUCT_STATUS_FULFILLED;
            },
            [ fetchProducts.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.findAll.data;
                state.findAll.error = action.error;
                state.findAll.status = PRODUCT_STATUS_REJECTED;
            },
            [ fetchProductById.pending ]: ( state ) =>
            {
                state.findById.status = PRODUCT_STATUS_PENDING;
            },
            [ fetchProductById.fulfilled ]: ( state, action ) =>
            {
                state.findById.data = action.payload;
                state.findById.updatedAt = new Date().getTime() / 1000;
                state.findById.status = PRODUCT_STATUS_FULFILLED;
            },
            [ fetchProductById.rejected ]: ( state, action ) =>
            {
                state.findById.data = initialState.findById.data;
                state.findById.error = action.error;
                state.findById.status = PRODUCT_STATUS_REJECTED;
            },
            [ fetchProductAttributes.pending ]: ( state ) =>
            {
                state.findAllAttributes.status = PRODUCT_STATUS_PENDING;
            },
            [ fetchProductAttributes.fulfilled ]: ( state, action ) =>
            {
                state.findAllAttributes.data = action.payload;
                state.findAllAttributes.updatedAt = new Date().getTime() / 1000;
                state.findAllAttributes.status = PRODUCT_STATUS_FULFILLED;
            },
            [ fetchProductAttributes.rejected ]: ( state, action ) =>
            {
                state.findAllAttributes.data = initialState.findAllAttributes.data;
                state.findAllAttributes.error = action.error;
                state.findAllAttributes.status = PRODUCT_STATUS_REJECTED;
            },
            [ fetchProductAttributeById.pending ]: ( state ) =>
            {
                state.findAttributeById.status = PRODUCT_STATUS_PENDING;
            },
            [ fetchProductAttributeById.fulfilled ]: ( state, action ) =>
            {
                state.findAttributeById.data = action.payload;
                state.findAttributeById.updatedAt = new Date().getTime() / 1000;
                state.findAttributeById.status = PRODUCT_STATUS_FULFILLED;
            },
            [ fetchProductAttributeById.rejected ]: ( state, action ) =>
            {
                state.findAttributeById.data = initialState.findAttributeById.data;
                state.findAttributeById.error = action.error;
                state.findAttributeById.status = PRODUCT_STATUS_REJECTED;
            },
            [ insertProductAttribute.pending ]: ( state ) =>
            {
                state.insertAttribute.status = PRODUCT_STATUS_PENDING;
            },
            [ insertProductAttribute.fulfilled ]: ( state, action ) =>
            {
                state.insertAttribute.data = action.payload;
                state.insertAttribute.status = PRODUCT_STATUS_FULFILLED;
            },
            [ insertProductAttribute.rejected ]: ( state, action ) =>
            {
                state.insertAttribute.data = initialState.insertAttribute.data;
                state.insertAttribute.error = action.error;
                state.insertAttribute.status = PRODUCT_STATUS_REJECTED;
            },
            [ patchProductAttribute.pending ]: ( state ) =>
            {
                state.patchAttribute.status = PRODUCT_STATUS_PENDING;
            },
            [ patchProductAttribute.fulfilled ]: ( state, action ) =>
            {
                state.patchAttribute.data = action.payload;
                state.patchAttribute.status = PRODUCT_STATUS_FULFILLED;
            },
            [ patchProductAttribute.rejected ]: ( state, action ) =>
            {
                state.patchAttribute.data = initialState.patchAttribute.data;
                state.patchAttribute.error = action.error;
                state.patchAttribute.status = PRODUCT_STATUS_REJECTED;
            },
            [ deleteProductAttribute.pending ]: ( state ) =>
            {
                state.deleteAttribute.status = PRODUCT_STATUS_PENDING;
            },
            [ deleteProductAttribute.fulfilled ]: ( state, action ) =>
            {
                state.deleteAttribute.data = action.payload;
                state.deleteAttribute.status = PRODUCT_STATUS_FULFILLED;
            },
            [ deleteProductAttribute.rejected ]: ( state, action ) =>
            {
                state.deleteAttribute.data = initialState.deleteAttribute.data;
                state.deleteAttribute.error = action.error;
                state.deleteAttribute.status = PRODUCT_STATUS_REJECTED;
            },
            [ fetchProductPrices.pending ]: ( state ) =>
            {
                state.findAllPrices.status = PRODUCT_STATUS_PENDING;
            },
            [ fetchProductPrices.fulfilled ]: ( state, action ) =>
            {
                state.findAllPrices.data = action.payload;
                state.findAllPrices.updatedAt = new Date().getTime() / 1000;
                state.findAllPrices.status = PRODUCT_STATUS_FULFILLED;
            },
            [ fetchProductPrices.rejected ]: ( state, action ) =>
            {
                state.findAllPrices.data = initialState.findAllPrices.data;
                state.findAllPrices.error = action.error;
                state.findAllPrices.status = PRODUCT_STATUS_REJECTED;
            },
        }
    }
);

export const {
    resetPersistProduct,
    resetPatchProduct,
    resetDeleteProduct,
    resetPersistAttribute,
    resetPatchAttribute,
    resetDeleteAttribute
} = productSlice.actions;

export const findProducts = state => state.product.findAll;
export const findProductById = state => state.product.findById;
export const findInsertProduct = state => state.product.insertProduct;
export const findPatchProduct = state => state.product.patchProduct;
export const findDeleteProduct = state => state.product.deleteProduct;
export const findProductAttributes = state => state.product.findAllAttributes;
export const findProductAttributeById = state => state.product.findAttributeById;
export const findProductPrices = state => state.product.findAllPrices;
export const findInsertProductAttribute = state => state.product.insertAttribute;
export const findPatchProductAttribute = state => state.product.patchAttribute;
export const findDeleteProductAttribute = state => state.product.deleteAttribute;

export default productSlice.reducer;