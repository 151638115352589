import IsAuthenticatedTemplate from "./features/common/IsAuthenticatedTemplate";
import IsNotAuthenticatedTemplate from "./features/common/IsNotAuthenticatedTemplate";
import React from "react";
import { useSelector } from "react-redux";
import { findAuthenticationToken } from "./features/account/accountSlice";
import Flex from "./features/common/Flex";

const Content = () =>
{
    const authenticationToken = useSelector( findAuthenticationToken );
    const isAuthenticated = !!authenticationToken;

    return (
        <Flex>
            {
                isAuthenticated === true
                    ? <IsAuthenticatedTemplate/>
                    : <IsNotAuthenticatedTemplate/>
            }
        </Flex>
    );
}

export default Content;