import getOptions from "../options";
import { API_PRODUCTS_ATTRIBUTE, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProductAttributeById( attributeId, options )
{
    return GET(
        getHost() + API_PRODUCTS_ATTRIBUTE( attributeId ),
        getOptions( options )
    );
}