import { GET, PATCH, POST } from "../utils/fetch";
import { API_ORDER, API_ORDER_DOCUMENTS, API_ORDERS, getHost } from "../app/constants";
import getOptions from "./options";

export default class Orders
{
    constructor( token )
    {
        this.options = {
            authorization: {
                token,
            }
        };
    }

    insert( body )
    {
        return POST(
            getHost() + API_ORDERS,
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    patch( orderId, body )
    {
        return PATCH(
            getHost() + API_ORDER( orderId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    findAll()
    {
        return GET(
            getHost() + API_ORDERS,
            getOptions( this.options )
        );
    }

    findById( orderId )
    {
        return GET(
            getHost() + API_ORDER( orderId ),
            getOptions( this.options )
        );
    }

    getDocumentsByOrderId( orderId )
    {
        return GET(
            getHost() + API_ORDER_DOCUMENTS( orderId ),
            getOptions( this.options )
        );
    }

    patchDocuments( orderId, body )
    {
        return PATCH(
            getHost() + API_ORDER_DOCUMENTS( orderId ),
            {
                ...getOptions( this.options ),
                body,
            }
        );
    }
}

export const useOrdersApi = ( token ) => new Orders( token );