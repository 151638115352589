import 'whatwg-fetch';
import '../polyfills/FormData.serialize';
import UnauthorizedError from "./errors/UnauthorizedError";
import UnprocessableEntityError from "./errors/UnprocessableEntityError";
import NotFoundError from "./errors/NotFoundError";

const DEFAULT_OPTIONS = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
    },
};

const checkStatus = ( response, statusArray = [ 401, 404, 405, 422, 500, ] ) =>
{

    if ( !statusArray.includes( response.status ) )
    {
        return response;
    }

    switch ( response.status )
    {
        case 401:
            throw new UnauthorizedError();
        case 404:
            throw new NotFoundError();
        case 422:
            throw new UnprocessableEntityError();
        default:
            throw new Error( `${ response.status }: ${ response.statusText }` );
    }

};

export const parseResponse = ( response, defaultResponseType = 'application/json' ) =>
{
    const responseStatusCode = response.status;
    const responseContentType = response.headers.get( 'content-type' ) || defaultResponseType;

    switch ( true )
    {
        case responseContentType.includes( 'application/json' ):
            // 304 is a null body status > https://fetch.spec.whatwg.org/#http-network-or-cache-fetch
            if ( responseStatusCode === 304 )
            {
                return Promise.resolve( {
                    success: true,
                } );
            }
            return response.json();
        case responseContentType.includes( 'application/pdf' ):
            return response.blob();
        default:
            return response.text();
    }
};

const request = ( url, init ) => window.fetch( url, init )
                                       .then( ( response ) => checkStatus( response ) )
                                       .then( ( response ) => parseResponse( response ) );

export const GET = ( url, options = {} ) =>
{
    const init = {
        ...DEFAULT_OPTIONS,
        ...options,
        method: 'GET',
    };

    if ( init.body )
    {
        if ( !( init.body instanceof FormData ) )
        {
            throw new Error( 'body must be an instance of FormData' );
        }

        const query = init.body.serialize();
        delete init.body;

        return request( `${ url }?${ query }`, init );
    }

    return request( url, init );
};

export const POST = ( url, options = {} ) =>
{
    const init = {
        ...DEFAULT_OPTIONS,
        ...options,
        method: 'POST',
        body: JSON.stringify( options.body ?? {} )
    };

    return request( url, init );
};

export const UPLOAD = ( url, options = {} ) =>
{
    const init = {
        ...DEFAULT_OPTIONS,
        ...options,
        method: 'POST',
        body: options.body,
    };

    return request( url, init );
};

export const PATCH = ( url, options = {} ) =>
{
    const init = {
        ...DEFAULT_OPTIONS,
        ...options,
        method: 'PATCH',
        body: JSON.stringify( options.body ?? {} )
    };

    return request( url, init );
};

export const DELETE = ( url, options = {} ) =>
{
    const init = {
        ...DEFAULT_OPTIONS,
        ...options,
        method: 'DELETE',
        body: JSON.stringify( options.body ?? {} )
    };

    return request( url, init );
};