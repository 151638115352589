import { API_PRODUCTS_ATTRIBUTE, getHost } from "../../app/constants";
import { PATCH } from "../../utils/fetch";
import getOptions from "../options";

export default function patchProductAttribute( attributeId, body, options )
{
    return PATCH(
        getHost() + API_PRODUCTS_ATTRIBUTE( attributeId ),
        {
            ...getOptions( options ),
            body
        }
    );
}