import { API_CUSTOMER_ADDRESSES, getHost } from "../../app/constants";
import { POST } from "../../utils/fetch";
import getOptions from "../options";

export default function insertCustomerAddress( customerId, body, options )
{
    return POST(
        getHost() + API_CUSTOMER_ADDRESSES( customerId ),
        {
            ...getOptions( options ),
            body
        }
    );
}