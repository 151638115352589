import getOptions from "../options";
import { API_PRODUCTS, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProducts( options )
{
    return GET(
        getHost() + API_PRODUCTS,
        getOptions( options )
    );
}