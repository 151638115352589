import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { Route, Switch } from "react-router-dom";
import { LOGIN_PATH } from "../../config/navigation";
import Login from "../account/login/Login";
import RedirectToLogin from "./RedirectToLogin";

const IsNotAuthenticatedTemplate = () =>
{
    return (
        <Suspense fallback={ <CircularProgress/> }>
            <Switch>
                <Route exact path={ LOGIN_PATH } component={ Login }/>
                <Route path="*" component={ RedirectToLogin }/>
            </Switch>
        </Suspense>
    );
};

export default IsNotAuthenticatedTemplate;