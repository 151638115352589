import { API_PRODUCTS_ATTRIBUTES, getHost } from "../../app/constants";
import { POST } from "../../utils/fetch";
import getOptions from "../options";

export default function insertProductAttribute( body, options )
{
    return POST(
        getHost() + API_PRODUCTS_ATTRIBUTES,
        {
            ...getOptions( options ),
            body
        }
    );
}