import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import api_getCustomers from "../../api/customer/getCustomers";
import api_getCustomerById from "../../api/customer/getCustomerById";
import api_insertCustomer from "../../api/customer/insertCustomer";
import api_patchCustomerById from "../../api/customer/patchCustomerById";
import api_deleteCustomerById from "../../api/customer/deleteCustomerById";
import api_insertCustomerAddress from "../../api/customer/insertCustomerAddress";
import api_patchCustomerAddressById from "../../api/customer/patchCustomerAddressById";
import getOrdersByCustomerId from "../../api/customer/getOrdersByCustomerId";
import getAddressesByCustomerId from "../../api/customer/getAddressesByCustomerId";

export const CUSTOMER_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const CUSTOMER_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const CUSTOMER_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const CUSTOMER_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const fetchCustomers = createAsyncThunk(
    'customers/findAll',
    async ( undefined, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getCustomers( { authorization } ).then( ( response ) =>
            {
                const { data = {}, errors = null } = response;

                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchCustomerById = createAsyncThunk(
    'customers/findById',
    async ( customerId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_getCustomerById( customerId, { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchAddressesByCustomerId = createAsyncThunk(
    'customers/findAddressesByCustomerId',
    async ( customerId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await getAddressesByCustomerId( customerId, { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const fetchOrdersByCustomerId = createAsyncThunk(
    'customers/findOrdersByCustomerId',
    async ( customerId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await getOrdersByCustomerId( customerId, { authorization } ).then( ( response ) =>
            {
                const { data = [], errors = null } = response;
                if ( errors !== null )
                {
                    return rejectWithValue( errors );
                }

                return data;
            }
        );
    }
);

export const insertCustomer = createAsyncThunk(
    'customers/insert',
    async ( { body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_insertCustomer( body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const patchCustomerById = createAsyncThunk(
    'customers/patchById',
    async ( { customerId, body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_patchCustomerById( customerId, body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const deleteCustomerById = createAsyncThunk(
    'customers/deleteById',
    async ( attributeId, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_deleteCustomerById( attributeId, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            )
            .catch( () =>
            {
                return rejectWithValue( {} );
            } );
    }
);

export const insertCustomerAddress = createAsyncThunk(
    'customers/addresses/insert',
    async ( { customerId, body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_insertCustomerAddress( customerId, body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

export const patchCustomerAddressById = createAsyncThunk(
    'customers/addresses/patchById',
    async ( { customerId, addressId, body }, { getState, rejectWithValue } ) =>
    {
        const authorization = {
            token: findAuthenticationToken( getState() )
        };
        return await api_patchCustomerAddressById( customerId, addressId, body, { authorization } )
            .then( ( response ) =>
                {
                    const { data = {}, errors = null } = response;

                    if ( errors !== null )
                    {
                        return rejectWithValue( errors );
                    }

                    return data;
                }
            );
    }
);

const initialState = {
    findAll: {
        data: [],
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
        updatedAt: null,
    },
    findById: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
        updatedAt: null,
    },
    findAddressesByCustomerId: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
        updatedAt: null,
    },
    findOrdersByCustomerId: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
        updatedAt: null,
    },
    insertCustomer: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
    },
    patchCustomer: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
    },
    deleteCustomer: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
    },
    insertCustomerAddress: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
    },
    patchCustomerAddress: {
        data: {},
        errors: null,
        status: CUSTOMER_STATUS_IDLE,
    },
};

export const customerSlice = createSlice( {
        name: 'customer',
        initialState,
        // slice-specific reducers here
        reducers: {
            resetInsertCustomer: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        insertCustomer: initialState.insertCustomer,
                    }
                )
            },
            resetPatchCustomer: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        patchCustomer: initialState.patchCustomer,
                    }
                )
            },
            resetDeleteCustomer: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        deleteCustomer: initialState.deleteCustomer,
                    }
                )
            },
            resetInsertCustomerAddress: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        insertCustomerAddress: initialState.insertCustomerAddress,
                    }
                )
            },
            resetPatchCustomerAddress: ( state ) =>
            {
                Object.assign(
                    state,
                    {
                        patchCustomerAddress: initialState.patchCustomerAddress,
                    }
                )
            },
        },
        // normal reducer logic
        extraReducers: {
            [ fetchCustomers.pending ]: ( state ) =>
            {
                state.findAll.status = CUSTOMER_STATUS_PENDING;
            },
            [ fetchCustomers.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.updatedAt = new Date().getTime() / 1000;
                state.findAll.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ fetchCustomers.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.findAll.data;
                state.findAll.error = action.error;
                state.findAll.status = CUSTOMER_STATUS_REJECTED;
            },
            [ fetchCustomerById.pending ]: ( state ) =>
            {
                state.findById.status = CUSTOMER_STATUS_PENDING;
            },
            [ fetchCustomerById.fulfilled ]: ( state, action ) =>
            {
                state.findById.data = action.payload;
                state.findById.updatedAt = new Date().getTime() / 1000;
                state.findById.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ fetchCustomerById.rejected ]: ( state, action ) =>
            {
                state.findById.data = initialState.findById.data;
                state.findById.error = action.error;
                state.findById.status = CUSTOMER_STATUS_REJECTED;
            },
            [ fetchAddressesByCustomerId.pending ]: ( state ) =>
            {
                state.findAddressesByCustomerId.status = CUSTOMER_STATUS_PENDING;
            },
            [ fetchAddressesByCustomerId.fulfilled ]: ( state, action ) =>
            {
                state.findAddressesByCustomerId.data = action.payload;
                state.findAddressesByCustomerId.updatedAt = new Date().getTime() / 1000;
                state.findAddressesByCustomerId.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ fetchAddressesByCustomerId.rejected ]: ( state, action ) =>
            {
                state.findAddressesByCustomerId.data = initialState.findById.data;
                state.findAddressesByCustomerId.error = action.error;
                state.findAddressesByCustomerId.status = CUSTOMER_STATUS_REJECTED;
            },
            [ fetchOrdersByCustomerId.pending ]: ( state ) =>
            {
                state.findOrdersByCustomerId.status = CUSTOMER_STATUS_PENDING;
            },
            [ fetchOrdersByCustomerId.fulfilled ]: ( state, action ) =>
            {
                state.findOrdersByCustomerId.data = action.payload;
                state.findOrdersByCustomerId.updatedAt = new Date().getTime() / 1000;
                state.findOrdersByCustomerId.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ fetchOrdersByCustomerId.rejected ]: ( state, action ) =>
            {
                state.findOrdersByCustomerId.data = initialState.findById.data;
                state.findOrdersByCustomerId.error = action.error;
                state.findOrdersByCustomerId.status = CUSTOMER_STATUS_REJECTED;
            },
            [ insertCustomer.pending ]: ( state ) =>
            {
                state.insertCustomer.status = CUSTOMER_STATUS_PENDING;
            },
            [ insertCustomer.fulfilled ]: ( state, action ) =>
            {
                state.insertCustomer.data = action.payload;
                state.insertCustomer.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ insertCustomer.rejected ]: ( state, action ) =>
            {
                state.insertCustomer.data = initialState.insertCustomer.data;
                state.insertCustomer.error = action.error;
                state.insertCustomer.status = CUSTOMER_STATUS_REJECTED;
            },
            [ patchCustomerById.pending ]: ( state ) =>
            {
                state.patchCustomer.status = CUSTOMER_STATUS_PENDING;
            },
            [ patchCustomerById.fulfilled ]: ( state, action ) =>
            {
                state.patchCustomer.data = action.payload;
                state.patchCustomer.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ patchCustomerById.rejected ]: ( state, action ) =>
            {
                state.patchCustomer.data = initialState.patchCustomer.data;
                state.patchCustomer.error = action.error;
                state.patchCustomer.status = CUSTOMER_STATUS_REJECTED;
            },
            [ deleteCustomerById.pending ]: ( state ) =>
            {
                state.deleteCustomer.status = CUSTOMER_STATUS_PENDING;
            },
            [ deleteCustomerById.fulfilled ]: ( state, action ) =>
            {
                state.deleteCustomer.data = action.payload;
                state.deleteCustomer.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ deleteCustomerById.rejected ]: ( state, action ) =>
            {
                state.deleteCustomer.data = initialState.deleteCustomer.data;
                state.deleteCustomer.error = action.error;
                state.deleteCustomer.status = CUSTOMER_STATUS_REJECTED;
            },
            [ insertCustomerAddress.pending ]: ( state ) =>
            {
                state.insertCustomerAddress.status = CUSTOMER_STATUS_PENDING;
            },
            [ insertCustomerAddress.fulfilled ]: ( state, action ) =>
            {
                state.insertCustomerAddress.data = action.payload;
                state.insertCustomerAddress.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ insertCustomerAddress.rejected ]: ( state, action ) =>
            {
                state.insertCustomerAddress.data = initialState.insertCustomerAddress.data;
                state.insertCustomerAddress.error = action.error;
                state.insertCustomerAddress.status = CUSTOMER_STATUS_REJECTED;
            },
            [ patchCustomerAddressById.pending ]: ( state ) =>
            {
                state.patchCustomerAddress.status = CUSTOMER_STATUS_PENDING;
            },
            [ patchCustomerAddressById.fulfilled ]: ( state, action ) =>
            {
                state.patchCustomerAddress.data = action.payload;
                state.patchCustomerAddress.status = CUSTOMER_STATUS_FULFILLED;
            },
            [ patchCustomerAddressById.rejected ]: ( state, action ) =>
            {
                state.patchCustomerAddress.data = initialState.patchCustomerAddress.data;
                state.patchCustomerAddress.error = action.error;
                state.patchCustomerAddress.status = CUSTOMER_STATUS_REJECTED;
            },
        }
    }
);

export const {
    resetInsertCustomer,
    resetPatchCustomer,
    resetDeleteCustomer,
    resetInsertCustomerAddress,
    resetPatchCustomerAddress
} = customerSlice.actions;

export const findCustomers = state => state.customer.findAll;
export const findCustomerById = state => state.customer.findById;
export const findInsertCustomer = state => state.customer.insertCustomer;
export const findPatchCustomer = state => state.customer.patchCustomer;
export const findDeleteCustomer = state => state.customer.deleteCustomer;
export const findInsertCustomerAddress = state => state.customer.insertCustomerAddress;
export const findPatchCustomerAddress = state => state.customer.patchCustomerAddress;
export const findAddressesByCustomerId = state => state.customer.findAddressesByCustomerId;
export const findOrdersByCustomerId = state => state.customer.findOrdersByCustomerId;

export default customerSlice.reducer;