import { API_PRODUCTS_ATTRIBUTE, getHost } from "../../app/constants";
import { DELETE } from "../../utils/fetch";
import getOptions from "../options";

export default function deleteProductAttributeById( attributeId, options )
{
    return DELETE(
        getHost() + API_PRODUCTS_ATTRIBUTE( attributeId ),
        {
            ...getOptions( options ),
        }
    );
}

