import { GET } from "../utils/fetch";
import { API_MANUFACTURERS, getHost } from "../app/constants";
import getOptions from "./options";

export default class Manufacturers
{
    constructor( token )
    {
        this.options = {
            authorization: {
                token,
            }
        };
    }

    findAll()
    {
        return GET(
            getHost() + API_MANUFACTURERS,
            getOptions( this.options )
        );
    }
}

export const useManufacturersApi = ( token ) => new Manufacturers( token );