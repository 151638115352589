import getOptions from "../options";
import { API_CUSTOMERS, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getCustomers( options )
{
    return GET(
        getHost() + API_CUSTOMERS,
        getOptions( options )
    );
}