import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import Slide from '@mui/material/Slide';
import { getGraphQLHost } from "./app/constants";

const client = new ApolloClient( {
    uri: getGraphQLHost(),
    cache: new InMemoryCache()
} );

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={ client }>
            <Provider store={ store }>
                <SnackbarProvider
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'left',
                    } }
                    TransitionComponent={ Slide }
                >
                    <App/>
                </SnackbarProvider>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById( 'root' )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
