import { combineReducers, configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';
import applicationReducer from "../features/common/applicationSlice";
import accountReducer from "../features/account/accountSlice";
import customerReducer from "../features/customer/customerSlice";
import customerAddressesReducer from "../features/customer/customerAddressesSlice";
import productReducer from "../features/product/productSlice";
import documentsReducer from "../features/documents/documentsSlice";
import ordersReducer from "../features/order/orderSlice";
import manufacturersReducer from "../features/manufacturer/manufacturerSlice";

export const findState = () =>
{
    try
    {
        const serializedState = localStorage.getItem( 'state' );
        if ( serializedState === null )
        {
            return {};
        }
        return JSON.parse( serializedState );
    }
    catch ( err )
    {
        return {};
    }
};

export const persistState = ( state ) =>
{
    try
    {
        const serializedState = JSON.stringify( state );
        localStorage.setItem( 'state', serializedState );
    }
    catch
    {
        // ignore write errors
    }
};

const combinedReducers = combineReducers(
    {
        application: applicationReducer,
        account: accountReducer,
        customer: customerReducer,
        customerAddresses: customerAddressesReducer,
        product: productReducer,
        documents: documentsReducer,
        orders: ordersReducer,
        manufacturers: manufacturersReducer,
    }
)

const rootReducer = ( state, action ) =>
{
    const { type, error } = action;

    if ( type === 'application/reset' || ( error && error.name && error.name === 'UnauthorizedError' ) )
    {
        state = undefined;
    }

    return combinedReducers( state, action );
}

const getFinaleStore = () =>
{
    const store = configureStore(
        {
            reducer: rootReducer,
            preloadedState: findState(),
        }
    );

    store.subscribe(
        throttle( () =>
            {
                persistState( store.getState() );
            },
            1000
        )
    );

    return store;
}

export default getFinaleStore();