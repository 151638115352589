import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Button, OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { persistAuthentication } from "../accountSlice";
import { useAccountApi } from "../../../api/account";
import { useSnackbar } from "notistack";
import CircularProgressBackdrop from "../../common/CircularProgressBackdrop";

const PREFIX = 'LoginForm';

const classes = {
    root: `${ PREFIX }-root`,
    marginTop: `${ PREFIX }-marginTop`,
    marginBottom: `${ PREFIX }-marginBottom`
};

const Form = styled( 'form' )( ( { theme } ) => ( {
    [ `& .${ classes.root }` ]: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    [ `& .${ classes.marginTop }` ]: {
        marginTop: theme.spacing( 1 ),
    },

    [ `& .${ classes.marginBottom }` ]: {
        marginBottom: theme.spacing( 1 ),
    }
} ) );

const LoginForm = ( { onSuccess, onError } ) =>
{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [ isFetching, setIsFetching ] = useState( false );
    const AccountApi = useAccountApi( null );

    const [ values, setValues ] = React.useState( {
        email: '',
        password: '',
        showPassword: false,
    } );

    const handleChange = ( prop ) => ( event ) =>
    {
        setValues( { ...values, [ prop ]: event.target.value } );
    };

    const handleClickShowPassword = () =>
    {
        setValues( { ...values, showPassword: !values.showPassword } );
    };

    const handleMouseDownPassword = ( event ) =>
    {
        event.preventDefault();
    };

    const handleSubmit = ( event ) =>
    {
        event.preventDefault();

        const { email, password } = values;
        setIsFetching( true );
        AccountApi.authentication( { email, password, } )
                  .then( ( response ) =>
                  {
                      enqueueSnackbar( 'Login erfolgreich.', { variant: "success" } );
                      setIsFetching( false );

                      dispatch( persistAuthentication( response.data ) );

                      if ( typeof onSuccess === 'function' )
                      {
                          onSuccess( { response } );
                      }
                  } )
                  .catch( error =>
                  {
                      enqueueSnackbar( 'Login fehlgeschlagen. Bitte überprüfen Sie Ihre Daten.', { variant: "error" } );
                      setIsFetching( false );

                      if ( typeof onError === 'function' )
                      {
                          onError( { error } );
                      }
                  } )
    }

    return (
        <Form onSubmit={ handleSubmit }>
            <CircularProgressBackdrop isOpen={ isFetching }/>
            <Grid>
                <Grid item xs={ 12 }>
                    <TextField
                        onChange={ handleChange( 'email' ) }
                        label="E-Mail"
                        id="filled-start-adornment"
                        className={ clsx( classes.marginTop, classes.marginBottom ) }
                        variant="outlined"
                        error={ false }
                        helperText={ null }
                        required
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <FormControl className={ clsx( classes.marginTop, classes.marginBottom ) } variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Passwort</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={ values.showPassword ? 'text' : 'password' }
                            value={ values.password }
                            required
                            onChange={ handleChange( 'password' ) }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={ handleClickShowPassword }
                                        onMouseDown={ handleMouseDownPassword }
                                        edge="end"
                                    >
                                        { values.showPassword ? <Visibility/> : <VisibilityOff/> }
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={"Passwort"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={ 12 }>
                    <Button
                        type={ "submit" }
                        size={ "large" } className={ clsx( classes.marginTop ) }
                        variant={ "contained" } color={ "primary" } fullWidth>Anmelden</Button>
                </Grid>
            </Grid>
        </Form>
    );
}
export default LoginForm;