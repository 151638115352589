import { API_CUSTOMER, getHost } from "../../app/constants";
import { DELETE } from "../../utils/fetch";
import getOptions from "../options";

export default function deleteCustomerById( customerId, options )
{
    return DELETE(
        getHost() + API_CUSTOMER( customerId ),
        {
            ...getOptions( options ),
        }
    );
}

