import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { findAuthenticationToken } from "../account/accountSlice";
import {
    REDUCER_STATUS_FULFILLED,
    REDUCER_STATUS_IDLE,
    REDUCER_STATUS_PENDING,
    REDUCER_STATUS_REJECTED
} from "../../app/constants";
import { useCustomerApi } from "../../api/customers";

export const CUSTOMER_ADDRESS_STATUS_IDLE = REDUCER_STATUS_IDLE;
export const CUSTOMER_ADDRESS_STATUS_PENDING = REDUCER_STATUS_PENDING;
export const CUSTOMER_ADDRESS_STATUS_FULFILLED = REDUCER_STATUS_FULFILLED;
export const CUSTOMER_ADDRESS_STATUS_REJECTED = REDUCER_STATUS_REJECTED;

export const fetchCustomerAddresses = createAsyncThunk(
    'customerAddresses/findAll',
    async ( { customerId }, { getState, rejectWithValue } ) =>
    {
        const CustomerApi = useCustomerApi( findAuthenticationToken( getState() ) );

        return await CustomerApi.getAddresses( customerId )
                                .then( ( response ) =>
                                    {
                                        const { data = {}, errors = null } = response;

                                        if ( errors !== null )
                                        {
                                            return rejectWithValue( errors );
                                        }

                                        return data;
                                    }
                                );
    }
);

export const fetchCustomerAddressById = createAsyncThunk(
    'customerAddresses/findById',
    async ( { customerId, addressId }, { getState, rejectWithValue } ) =>
    {
        const CustomerApi = useCustomerApi( findAuthenticationToken( getState() ) );

        return await CustomerApi.getAddress( customerId, addressId )
                                .then( ( response ) =>
                                    {
                                        const { data = [], errors = null } = response;
                                        if ( errors !== null )
                                        {
                                            return rejectWithValue( errors );
                                        }

                                        return data;
                                    }
                                );
    }
);

const initialState = {
    findAll: {
        data: [],
        errors: null,
        status: CUSTOMER_ADDRESS_STATUS_IDLE,
        updatedAt: null,
    },
    findById: {
        data: {},
        errors: null,
        status: CUSTOMER_ADDRESS_STATUS_IDLE,
        updatedAt: null,
    },
};

export const customerAddressesSlice = createSlice( {
        name: 'customerAddresses',
        initialState,
        // slice-specific reducers here
        reducers: {},
        // normal reducer logic
        extraReducers: {
            [ fetchCustomerAddresses.pending ]: ( state ) =>
            {
                state.findAll.status = CUSTOMER_ADDRESS_STATUS_PENDING;
            },
            [ fetchCustomerAddresses.fulfilled ]: ( state, action ) =>
            {
                state.findAll.data = action.payload;
                state.findAll.updatedAt = new Date().getTime() / 1000;
                state.findAll.status = CUSTOMER_ADDRESS_STATUS_FULFILLED;
            },
            [ fetchCustomerAddresses.rejected ]: ( state, action ) =>
            {
                state.findAll.data = initialState.findAll.data;
                state.findAll.error = action.error;
                state.findAll.status = CUSTOMER_ADDRESS_STATUS_REJECTED;
            },
            [ fetchCustomerAddressById.pending ]: ( state ) =>
            {
                state.findById.status = CUSTOMER_ADDRESS_STATUS_PENDING;
            },
            [ fetchCustomerAddressById.fulfilled ]: ( state, action ) =>
            {
                state.findById.data = action.payload;
                state.findById.updatedAt = new Date().getTime() / 1000;
                state.findById.status = CUSTOMER_ADDRESS_STATUS_FULFILLED;
            },
            [ fetchCustomerAddressById.rejected ]: ( state, action ) =>
            {
                state.findById.data = initialState.findById.data;
                state.findById.error = action.error;
                state.findById.status = CUSTOMER_ADDRESS_STATUS_REJECTED;
            },
        }
    }
);

// export const {} = customerAddressesSlice.actions;

export const findCustomerAddresses = state => state.customerAddresses.findAll;
export const findCustomerAddressById = state => state.customerAddresses.findById;

export default customerAddressesSlice.reducer;