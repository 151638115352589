import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const CircularProgressBackdrop = ( { isOpen = false } ) => (
    <Backdrop sx={ { zIndex: 2000, color: '#fff', } } open={ isOpen }>
        <CircularProgress color="inherit"/>
    </Backdrop>
);

CircularProgressBackdrop.propTypes = {
    isOpen: PropTypes.bool.isRequired,
}

export default CircularProgressBackdrop;