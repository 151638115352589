import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoginForm from "./LoginForm";
import { useQuery } from "../../../utils/useQuery";
import PasswordForm from "./PasswordForm";
import { useHistory } from "react-router-dom";
import { DASHBOARD_PATH } from "../../../config/navigation";

const VIEW_LOGIN = 'login';
const VIEW_PASSWORD = 'password';

const LoginCard = ( { setView } ) =>
{
    const history = useHistory();

    const onSuccess = () =>
    {
        history.push( DASHBOARD_PATH );
    }

    return (
        <>
            <Card sx={ { position: "relative" } }>
                <CardContent>
                    <Typography gutterBottom variant={ "h5" }
                                align={ "center" }>Anmelden</Typography>
                    <LoginForm onSuccess={ onSuccess }/>
                </CardContent>
            </Card>
            <Box pt={ 2 }>
                <Button
                    fullWidth
                    onClick={ () => setView( VIEW_PASSWORD ) }
                >
                    Passwort vergessen?
                </Button>
            </Box>

        </>
    );
}

const PasswordCard = ( { setView } ) =>
{
    return (
        <>
            <Card sx={ { position: "relative" } }>
                <CardContent>
                    <Typography gutterBottom variant={ "h5" }
                                align={ "center" }>Passwort vergessen</Typography>
                    <PasswordForm/>
                </CardContent>
            </Card>
            <Box pt={ 2 }>
                <Button
                    fullWidth
                    onClick={ () => setView( VIEW_LOGIN ) }
                >
                    zurück zum Login
                </Button>
            </Box>
        </>
    );
}

const Login = () =>
{
    const query = useQuery();
    const [ view, setView ] = useState( query.get( 'view' ) ?? VIEW_LOGIN );

    return (
        <Grid
            container
            spacing={ 0 }
            direction="column"
            alignItems="center"
            justifyContent={ "center" }
            sx={ { minHeight: '100vh' } }
        >
            <Grid item xs={ 10 } sm={ 8 } md={ 6 } lg={ 4 }>
                { view === VIEW_LOGIN ? <LoginCard setView={ setView }/> : null }
                { view === VIEW_PASSWORD ? <PasswordCard setView={ setView }/> : null }
            </Grid>
        </Grid>
    );
}

export default Login;