import { API_DOCUMENTS, getHost } from "../../app/constants";
import { UPLOAD } from "../../utils/fetch";
import getOptions from "../options";

export default function uploadDocuments( body, options )
{
    return UPLOAD(
        getHost() + API_DOCUMENTS,
        {
            ...getOptions( options ),
            body
        }
    );
}