import { API_CUSTOMER, getHost } from "../../app/constants";
import { PATCH } from "../../utils/fetch";
import getOptions from "../options";

export default function patchCustomerById( customerId, body, options )
{
    return PATCH(
        getHost() + API_CUSTOMER( customerId ),
        {
            ...getOptions( options ),
            body
        }
    );
}