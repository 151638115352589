import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import React, { useEffect } from 'react';
import './polyfills/Array.unique';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchProductAttributes,
    findDeleteProductAttribute,
    findInsertProductAttribute,
    findPatchProductAttribute,
    PRODUCT_STATUS_FULFILLED
} from "./features/product/productSlice";
import {
    CUSTOMER_STATUS_FULFILLED,
    fetchCustomers,
    findDeleteCustomer,
    findInsertCustomer,
    findPatchCustomer
} from "./features/customer/customerSlice";
import Content from "./Content";
import moment from "moment";
import "moment/locale/de";

moment.locale( 'de' );

let theme = createTheme( {
    palette: {
        primary: {
            main: "#333",
        },
        background: {
            default: "#fafafa",
        },
        black: {
            main: '#000000'
        }
    },
    components: {
        MuiFormControl: {
            defaultProps: {
                margin: "dense",
                fullWidth: true,
            }
        },
        MuiTextField: {
            defaultProps: {
                margin: "dense",
                fullWidth: true,
            }
        },
        MuiToolbar: {
            defaultProps: {
                // disableGutters: true,
                // variant: "dense",
            }
        },
        MuiBadge: {
            variants: [
                {
                    props: {
                        variant: 'dot',
                        color: 'black',
                    },
                    style: {
                        backgroundColor: '#555555',
                    }
                }
            ]
        }
    },
    typography: {
        // h1: {
        //     fontSize: "1.5rem",
        //     marginBottom: "4px",
        // },
        // h2: {
        //     fontSize: "1.25rem",
        //     marginBottom: "4px",
        // },
        // h3: {
        //     fontSize: "1.125rem",
        //     marginBottom: "4px",
        // },
        // h4: {
        //     fontSize: "1.125rem",
        //     fontWeight: "lighter",
        //     marginBottom: "4px",
        // },
        // h5: {
        //     fontSize: "1rem",
        //     marginBottom: "4px",
        // },
        // h6: {
        //     fontSize: "1rem",
        //     fontWeight: "lighter",
        //     marginBottom: "4px",
        // },
        // subtitle1: {
        //     color: colors.grey[ "600" ],
        //     // fontWeight: "bold",
        //     fontSize: "0.875rem",
        //     whiteSpace: "nowrap",
        //     textOverflow: "ellipsis",
        //     overflow: "hidden",
        // },
        // subtitle2: {
        //     color: colors.grey[ "600" ],
        //     fontSize: "0.75rem",
        //     whiteSpace: "nowrap",
        //     textOverflow: "ellipsis",
        //     overflow: "hidden",
        // }
    }
} );
theme = createTheme(
    theme,
    {
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        outline: "none !important",
                    },
                    cell: {
                        outline: "none !important",
                    }
                }
            }
        //     MuiToolbar: {
        //         styleOverrides: {
        //             gutters: {
        //                 [ theme.breakpoints.up( "sm" ) ]: {
        //                     paddingRight: theme.spacing( 3 ),
        //                     paddingLeft: theme.spacing( 3 ),
        //                 }
        //             }
        //         }
        //     }
        // }
        // overrides: {
        //     // MuiDialogContent: {
        //     //     root: {
        //     //         minHeight: "200px",
        //     //     }
        //     // },
        //     MuiDialogActions: {
        //         root: {
        //             padding: "12px 24px",
        //         }
        //     },
        //     MuiPaper: {
        //         root: {
        //             "& + .MuiPaper-root": {
        //                 marginTop: "16px",
        //             }
        //         }
        //     },
        //     MuiCardHeader: {
        //         title: {
        //             fontSize: "14px",
        //             whiteSpace: "nowrap",
        //             textOverflow: "ellipsis",
        //             overflow: "hidden",
        //         }
        //     },
        //     MuiCardContent: {
        //         root: {
        //             "&:last-child": {
        //                 paddingBottom: "16px",
        //             }
        //         },
        //     },
        //     MuiDataGrid: {
        //         root: {
        //             "& .MuiDataGrid-columnSeparator": {
        //                 display: "none",
        //             },
        //         },
        //     }
        },
    },
);

function App()
{
    const dispatch = useDispatch();
    const insertProductAttribute = useSelector( findInsertProductAttribute );
    const patchProductAttribute = useSelector( findPatchProductAttribute );
    const deleteProductAttribute = useSelector( findDeleteProductAttribute );
    const insertCustomer = useSelector( findInsertCustomer );
    const patchCustomer = useSelector( findPatchCustomer );
    const deleteCustomer = useSelector( findDeleteCustomer );

    // load data after related action => TODO better solution? => persisting of everything should be without redux so you can use promises
    useEffect(
        () =>
        {
            if ( insertProductAttribute.status === PRODUCT_STATUS_FULFILLED )
            {
                dispatch( fetchProductAttributes() );
            }
        },
        [ dispatch, insertProductAttribute, ]
    )

    useEffect(
        () =>
        {
            if ( patchProductAttribute.status === PRODUCT_STATUS_FULFILLED )
            {
                dispatch( fetchProductAttributes() );
            }
        },
        [ dispatch, patchProductAttribute, ]
    )

    useEffect(
        () =>
        {
            if ( deleteProductAttribute.status === PRODUCT_STATUS_FULFILLED )
            {
                dispatch( fetchProductAttributes() );
            }
        },
        [ dispatch, deleteProductAttribute, ]
    )

    useEffect(
        () =>
        {
            if ( insertCustomer.status === CUSTOMER_STATUS_FULFILLED )
            {
                dispatch( fetchCustomers() );
            }
        },
        [ dispatch, insertCustomer, ]
    )

    useEffect(
        () =>
        {
            if ( patchCustomer.status === CUSTOMER_STATUS_FULFILLED )
            {
                dispatch( fetchCustomers() );
            }
        },
        [ dispatch, patchCustomer, ]
    )

    useEffect(
        () =>
        {
            if ( deleteCustomer.status === CUSTOMER_STATUS_FULFILLED )
            {
                dispatch( fetchCustomers() );
            }
        },
        [ dispatch, deleteCustomer, ]
    )

    return (
        <BrowserRouter>
            <ThemeProvider theme={ theme }>
                <CssBaseline/>
                <Content/>
            </ThemeProvider>
        </BrowserRouter>
    );

}

export default App;
