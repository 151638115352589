import { GET, PATCH, POST } from "../utils/fetch";
import {
    API_AUTHENTICATION,
    API_AUTHENTICATION_PASSWORD_RESET,
    API_USER,
    API_USER_ME,
    API_USER_PASSWORD,
    API_USERS,
    getHost
} from "../app/constants";
import getOptions from "./options";

export default class Account
{
    constructor( token )
    {
        this.options = {
            authorization: {
                token,
            }
        };
    }

    authentication( body )
    {
        return POST(
            getHost() + API_AUTHENTICATION,
            {
                body
            }
        );
    }

    find()
    {
        return GET(
            getHost() + API_USER_ME,
            getOptions( this.options )
        );
    }

    findAll()
    {
        return GET(
            getHost() + API_USERS,
            getOptions( this.options )
        );
    }

    patch( userId, body )
    {
        return PATCH(
            getHost() + API_USER( userId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }

    resetPassword( body )
    {
        return POST(
            getHost() + API_AUTHENTICATION_PASSWORD_RESET,
            {
                body
            }
        );
    }

    patchPassword( userId, body )
    {
        return PATCH(
            getHost() + API_USER_PASSWORD( userId ),
            {
                ...getOptions( this.options ),
                body
            }
        );
    }
}

export const useAccountApi = ( token ) => new Account( token );