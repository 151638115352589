import getOptions from "../options";
import { API_PRODUCTS_ATTRIBUTES, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProductAttributes( options )
{
    return GET(
        getHost() + API_PRODUCTS_ATTRIBUTES,
        getOptions( options )
    );
}