import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { ExitToApp } from "@mui/icons-material";
import { ACCOUNT_PATH, LOGIN_PATH, PRIMARY_NAVIGATION, SECONDARY_NAVIGATION } from "../../config/navigation";
import ListItemLink from "./ListItemLink";
import { Router } from "./Router";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "./applicationSlice";
import { findAuthentication } from "../account/accountSlice";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import FlexGrow from "./FlexGrow";

const drawerWidth = 200;

const openedMixin = ( theme ) => ( {
    width: drawerWidth,
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    } ),
    overflowX: 'hidden',
} );

const closedMixin = ( theme ) => ( {
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    } ),
    overflowX: 'hidden',
    width: `calc(${ theme.spacing( 7 ) } + 1px)`,
    [ theme.breakpoints.up( 'sm' ) ]: {
        width: `calc(${ theme.spacing( 8 ) } + 1px)`,
    },
} );

const Drawer = styled( MuiDrawer, { shouldForwardProp: ( prop ) => prop !== 'open' } )(
    ( { theme, open } ) => ( {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...( open && {
            ...openedMixin( theme ),
            '& .MuiDrawer-paper': openedMixin( theme ),
        } ),
        ...( !open && {
            ...closedMixin( theme ),
            '& .MuiDrawer-paper': closedMixin( theme ),
        } ),
    } ),
);


const IsAuthenticatedTemplate = () =>
{
    const history = useHistory();
    const dispatch = useDispatch();
    const authenticationSnapshot = useSelector( findAuthentication );

    const [ open, setOpen ] = React.useState( false );

    const handleDrawer = () =>
    {
        setOpen( !open );
    };

    const handleLogout = () =>
    {
        dispatch( reset() );
        history.push( LOGIN_PATH );
    }

    return (
        <>
            <AppBar position="fixed" sx={ { zIndex: ( theme ) => theme.zIndex.drawer + 1 } }>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={ handleDrawer }
                        edge="start"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <FlexGrow/>
                    <IconButton
                        aria-label="account of current user"
                        color="inherit"
                        component={ RouterLink }
                        to={ ACCOUNT_PATH.replace( ':userId', authenticationSnapshot.data.id ) }
                    >
                        <AccountCircle/>
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        onClick={ handleLogout }
                        color="inherit"
                    >
                        <ExitToApp/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={ open }>
                <Toolbar/>
                <List>
                    {
                        PRIMARY_NAVIGATION.map( ( element ) => (
                            <ListItem
                                key={ element.text }
                                button
                                component={ ListItemLink( { to: element.to } ) }>
                                { element.icon ? <ListItemIcon>{ element.icon }</ListItemIcon> : null }
                                <ListItemText secondary={ element.text }/>
                            </ListItem>
                        ) ) }
                </List>
                <Divider/>
                <List>
                    {
                        SECONDARY_NAVIGATION.map( ( element ) => (
                            <ListItem
                                key={ element.text }
                                button
                                component={ ListItemLink( { to: element.to } ) }>
                                { element.icon ? <ListItemIcon>{ element.icon }</ListItemIcon> : null }
                                <ListItemText secondary={ element.text }/>
                            </ListItem>
                        ) ) }
                </List>
            </Drawer>
            <Box component="main" sx={ { flexGrow: 1, } }>
                <Toolbar/>
                <Router/>
            </Box>
        </>
    );
};

export default IsAuthenticatedTemplate;