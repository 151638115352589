import getOptions from "../options";
import { API_CUSTOMER_ADDRESSES, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getAddressesByCustomerId( customerId, options )
{
    return GET(
        getHost() + API_CUSTOMER_ADDRESSES( customerId ),
        getOptions( options )
    );
}