import getOptions from "../options";
import { API_PRODUCT, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getProductById( productId, options )
{
    return GET(
        getHost() + API_PRODUCT( productId ),
        getOptions( options )
    );
}