class UnauthorizedError extends Error
{
    constructor( message )
    {
        super( message );
        this.name = 'UnauthorizedError';
        this.code = 401;
    }
}

export default UnauthorizedError;