export const getHost = () =>
{
    if ( window.apiHost !== undefined )
    {
        return window.apiHost;
    }

    switch ( window.location.hostname )
    {
        case '127.0.0.1':
        case 'localhost':
            window.apiHost = 'https://127.0.0.1:8000';
            break;
        case 'crm-sc.stages.patrick-helbing.de':
            window.apiHost = 'https://api.crm-sc.stages.patrick-helbing.de';
            break;
        case 'app.sachsencaravan.de':
            window.apiHost = 'https://api.app.sachsencaravan.de';
            break;
        case 'app-stage.sachsencaravan.de':
            window.apiHost = 'https://api.app-stage.sachsencaravan.de';
            break;
        default:
            throw Error( 'invalid host' );
    }

    return window.apiHost;
};

export const getGraphQLHost = () =>
{
    switch ( window.location.hostname )
    {
        case '127.0.0.1':
        case 'localhost':
            return 'https://127.0.0.1:8000/api/graphql';
        case 'crm-sc.stages.patrick-helbing.de':
            return 'https://api.crm-sc.stages.patrick-helbing.de/api/graphql';
        case 'app.sachsencaravan.de':
            return 'https://api.app.sachsencaravan.de/api/graphql';
        case 'app-stage.sachsencaravan.de':
            return 'https://api.app-stage.sachsencaravan.de/api/graphql';
        default:
            throw Error( 'invalid host' );
    }
}

export const REDUCER_STATUS_IDLE = 'idle';
export const REDUCER_STATUS_PENDING = 'pending';
export const REDUCER_STATUS_FULFILLED = 'fulfilled';
export const REDUCER_STATUS_REJECTED = 'rejected';

/* Authentication Endpoint */
export const API_AUTHENTICATION = `/authentication`;
export const API_AUTHENTICATION_PASSWORD_RESET = `/authentication/password/reset`;

/* Account Endpoints */
export const API_USERS = `/users`;
export const API_USER_ME = `/users/me`;
export const API_USER = ( userId ) => `/users/${ userId }`;
export const API_USER_PASSWORD = ( userId ) => `/users/${ userId }/password`;

/* Products Endpoints */
export const API_PRODUCTS = `/products`;
export const API_PRODUCTS_ATTRIBUTES = `/products/attributes`;
export const API_PRODUCTS_ATTRIBUTE = ( attributeId ) => `/products/attributes/${ attributeId }`;
export const API_PRODUCTS_PRICES = `/products/prices`;
export const API_PRODUCT = ( productId ) => `/products/${ productId }`;
export const API_PRODUCT_ATTRIBUTES_VALUES = ( productId ) => `/products/${ productId }/attributes/values`;
export const API_PRODUCT_PRICES_VALUES = ( productId ) => `/products/${ productId }/prices/values`;
export const API_PRODUCT_DOCUMENTS = ( productId ) => `/products/${ productId }/documents`;
export const API_PRODUCT_NOTES = ( productId ) => `/products/${ productId }/notes`;

/* Customer Endpoints */
export const API_CUSTOMERS = `/customers`;
export const API_CUSTOMER = ( customerId ) => `/customers/${ customerId }`;
export const API_CUSTOMER_ADDRESSES = ( customerId ) => `/customers/${ customerId }/addresses`;
export const API_CUSTOMER_ADDRESS = ( customerId, addressId ) => `/customers/${ customerId }/addresses/${ addressId }`;
export const API_CUSTOMER_ORDERS = ( customerId ) => `/customers/${ customerId }/orders`;
export const API_CUSTOMER_ORDER = ( customerId, orderId ) => `/customers/${ customerId }/orders/${ orderId }`;

/* Documents Endpoints */
export const API_DOCUMENTS = `/documents`;
export const API_DOCUMENT = ( documentId ) => `/documents/${ documentId }`;

/* Documents Endpoints */
export const API_MANUFACTURERS = `/manufacturers`;

/* Orders Endpoints */
export const API_ORDERS = '/orders';
export const API_ORDER = ( orderId ) => `/orders/${ orderId }`;
export const API_ORDER_DOCUMENTS = ( orderId ) => `/orders/${ orderId }/documents`;

export const productDeliveryStatusMap = [
    {
        value: 'order',
        label: 'Bestellt',
    },
    {
        value: 'stock',
        label: 'Lager',
    },
    {
        value: 'customer',
        label: 'Kunde',
    },
]

export const productProcessStatusMap = [
    {
        value: 'ec-1',
        label: 'EC 1 - Ausstehend',
        color: '#dd0000'
    },
    {
        value: 'ec-2',
        label: 'EC 2 - Mit Mängel',
        color: '#cccc00'
    },
    {
        value: 'ec-3',
        label: 'EC 3 - Ohne Mängel',
        color: '#00aa00'
    },
    {
        value: 'gar-1',
        label: 'Garantie - Ausstehend',
        color: '#dd0000'
    },
    {
        value: 'gar-2',
        label: 'Garantie - In Arbeit',
        color: '#cccc00'
    },
    {
        value: 'gar-3',
        label: 'Garantie - Abgeschlossen',
        color: '#00aa00'
    },
];

export const orderStatusMap = [
    {
        value: 'new',
        label: 'Neu',
    },
    {
        value: 'progress',
        label: 'In Bearbeitung',
    },
    {
        value: 'rejected',
        label: 'Abgewiesen',
    },
    {
        value: 'completed',
        label: 'Abgeschlossen',
    },
];

export const gendersMap = [
    {
        value: 'male',
        label: 'Herr',
    },
    {
        value: 'female',
        label: 'Frau',
    },
];

export const countryCodesMap = [
    {
        value: 'DE',
        label: 'Deutschland',
    },
];

export const PRODUCT_ATTRIBUTE_TYPE_BOOL = 'bool';
export const PRODUCT_ATTRIBUTE_TYPE_NUMBER = 'number';
export const PRODUCT_ATTRIBUTE_TYPE_SELECT = 'select';
export const PRODUCT_ATTRIBUTE_TYPE_TEXT = 'text';

export const productAttributeTypes = [
    {
        type: PRODUCT_ATTRIBUTE_TYPE_TEXT,
        label: 'Textfeld',
    },
    {
        type: PRODUCT_ATTRIBUTE_TYPE_NUMBER,
        label: 'Zahl',
    },
    {
        type: PRODUCT_ATTRIBUTE_TYPE_SELECT,
        label: 'Auswahl',
    },
    {
        type: PRODUCT_ATTRIBUTE_TYPE_BOOL,
        label: 'Ja/Nein',
    },
]