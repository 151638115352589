import { API_PRODUCT, getHost } from "../../app/constants";
import { DELETE } from "../../utils/fetch";
import getOptions from "../options";

export default function deleteProductAttributeById( productId, options )
{
    return DELETE(
        getHost() + API_PRODUCT( productId ),
        {
            ...getOptions( options ),
        }
    );
}

