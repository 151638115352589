import getOptions from "../options";
import { API_CUSTOMER_ORDERS, getHost } from "../../app/constants";
import { GET } from "../../utils/fetch";

export default function getOrdersByCustomerId( customerId, options )
{
    return GET(
        getHost() + API_CUSTOMER_ORDERS( customerId ),
        getOptions( options )
    );
}