import { API_CUSTOMER_ADDRESS, getHost } from "../../app/constants";
import { PATCH } from "../../utils/fetch";
import getOptions from "../options";

export default function patchCustomerAddressById( customerId, addressId, body, options )
{
    return PATCH(
        getHost() + API_CUSTOMER_ADDRESS( customerId, addressId ),
        {
            ...getOptions( options ),
            body
        }
    );
}